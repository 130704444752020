
function evaluateCondition(condition: any, answers: any, level = 0, aslist = false): any[] | boolean {
  const results: any[] = [];
  // console.log(`evaluateCondition ${level}`, condition, answers);

  if (condition !== null && Array.isArray(condition)) {
    // console.log('evaluateCondition[array]', condition);
    for (const subcond of condition) {
      results.push(evaluateCondition(subcond, answers, level + 1));
    }
  } else if (condition !== null && typeof condition === "object") {
    // console.log('evaluateCondition[object]', condition);
    for (const [key, value] of Object.entries(condition)) {
      if (key.startsWith("$")) {
        // console.log(`evaluateCondition[object]:$ ${key}`);
        const evals = evaluateCondition(value, answers, level + 1, true);
        if (key === "$and") {
          if (Array.isArray(evals)) {
            results.push(evals.every((x) => x === true));
          } else {
            results.push(evals);
          }
        } else if (key === "$or") {
          // console.log(`evaluate $or: ${evals}`);
          if (Array.isArray(evals)) {
            results.push(evals.some((x) => x === true));
          } else {
            results.push(evals);
          }
        } else if (key === "$not") {
          results.push(!evals);
        }
      } else {
        // console.log('evaluateCondition[object]:no$', key, value)
        results.push(answers[key] === value);
      }
    }
  }

  if (aslist) {
    return results;
  }
  return results.every((x) => x === true);
}

function conditionIfPass(vIf: any, answers: any): boolean {
  console.log('conditionIfPass[q]', vIf);
  let condIf = true;

  if (vIf) {
    const vIfArray = vIf.split('.');
    for (let i = 2; i <= vIfArray.length; i++) {
      const vIfPart = vIfArray.slice(0, i).join('.');
      condIf = condIf && answers[vIfPart];
    }
  }
  return condIf;
}

function conditionPass(entry: any, answers: any): boolean {
  const vIf = entry.if;
  const vCond = entry.condition;

  let condIf = true;
  let condCond = true;

  if (vIf) {
    console.log('conditionPass', entry)
    condIf = conditionIfPass(vIf, answers);
  }
  if (vCond) {
    condCond = <boolean>evaluateCondition(vCond, answers);
  }
  // console.log('conditionPass', vIf, vCond, condIf, condCond)
  return condIf && condCond;
}

function getMissingMandatoryAnswers(questions: any, answers: any, qkey: string): string[] {
  const missing: any = [];
  let baseKey = qkey;
  for (const question of questions) {
    if (!conditionPass(question, answers)) {
      continue;
    }
    const key = `${baseKey}.${question.key}`
    if (question.mandatory && !answers[key]) {
      missing.push({
        key: key,
        name: question.name
      });
    }
  }
  return missing;
}

export { conditionPass, getMissingMandatoryAnswers };
