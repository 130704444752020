
























































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { path, range } from "../utils";
import { conditionPass } from "../conditions";

@Component({
  name: "Question",
})
export default class Question extends Vue {
  @Prop() public question: any;
  @Prop() public answers: any;
  @Prop() public staticAnswers: any;
  @Prop() public qkey: any;
  @Prop() public inner: any;

  get mergedAnswers() {
    return { ...this.answers, ...this.staticAnswers };
  }

  get years() {
    const now = new Date().getFullYear();
    let result: Array<string | number> = [];
    result = result.concat(range(now, 1979, -1));
    result.push("< 1980");
    return result;
  }

  get year_decades() {
    return ["< 1970", "< 1980", "< 1990", "< 2000", "< 2010", "< 2020", "< 2030"];
  }

  public getSelectOptions(name: string) {
    if (name === "year") {
      return this.years;
    } else if (name === "year-decades") {
      return this.year_decades;
    }
  }

  get filteredOptions() {
    return this.question.options.filter((option: any) => conditionPass(option, this.mergedAnswers))
  }

  get selectedOption() {
    if (this.question.options === undefined) {
      return;
    }
    const selectedValue = this.answers[this.qkey];
    for (const entry of this.question.options) {
      if (selectedValue === entry.key) {
        return entry;
      }
    }
  }

  get canShowOptions() {
    if (this.question.options === undefined) {
      return false;
    }
    if (path(this.question, ["input", "type"]) === "checkbox") {
      if (!this.booleanAnswer) {
        return false;
      }
    }
    return true;
  }

  get booleanAnswer() {
    return this.answers[this.qkey] !== undefined;
  }

  public setBooleanAnswer(value: any) {
    if (value === true) {
      Vue.set(this.answers, this.qkey, value);
    } else {
      Vue.set(this.answers, this.qkey, undefined);
    }
  }

  public getQuestionKey(qkey: string) {
    return this.qkey + "." + qkey;
  }

  public path(obj: any, items: any) {
    return path(obj, items);
  }
}
