import { render, staticRenderFns } from "./ManageBuildingEdit.vue?vue&type=template&id=7087b12e&scoped=true&"
import script from "./ManageBuildingEdit.vue?vue&type=script&lang=ts&"
export * from "./ManageBuildingEdit.vue?vue&type=script&lang=ts&"
import style0 from "./ManageBuildingEdit.vue?vue&type=style&index=0&id=7087b12e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7087b12e",
  null
  
)

/* custom blocks */
import block0 from "./ManageBuildingEdit.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QTabPanels,QTabPanel,QForm,QInput,QSelect,QList,QItem,QItemSection,QItemLabel,QToggle,QBtn,QCheckbox,QInnerLoading,QSpinner,QPage,QLayout});qInstall(component, 'directives', {Ripple});
