import { render, staticRenderFns } from "./Question.vue?vue&type=template&id=fa25c2f6&scoped=true&"
import script from "./Question.vue?vue&type=script&lang=ts&"
export * from "./Question.vue?vue&type=script&lang=ts&"
import style0 from "./Question.vue?vue&type=style&index=0&id=fa25c2f6&lang=scss&scoped=true&"
import style1 from "./Question.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa25c2f6",
  null
  
)

/* custom blocks */
import block0 from "./Question.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QSelect,QBtnToggle,QBtnGroup,QBtn});
