function path(obj: any, args: any) {
    for (const arg of args) {
        if (!obj || !obj.hasOwnProperty(arg)) {
            return undefined;
        }
        obj = obj[arg];
    }
    return obj;
}

function* iter(a: number, b: number, step: number = 1) {
    if (step > 0) {
        for (let i = b ? a : 0; i < (b || a); i += step) {
            yield i;
        }
    } else {
        for (let i = b ? a : 0; i > (b || a); i += step) {
            yield i;
        }
    }
}

const range = (a: number, b: number, step: number = 1) =>
    [...iter(a, b, step)];


function mod(n: number, m: number) {
    return ((n % m) + m) % m;
}

export {
    path,
    iter,
    range,
    mod,
};
