












import { Component, Prop, Vue } from "vue-property-decorator";
import Question from "../components/Question.vue";
import { conditionPass } from "../conditions";

@Component({
  name: "Questions",
  components: {
    Question,
  },
})
export default class Questions extends Vue {
  @Prop() public questions: any;
  @Prop() public title: any;
  @Prop() public answers: any;
  @Prop() public staticAnswers: any;
  @Prop() public qkey: any;

  get mergedAnswers() {
    return { ...this.answers, ...this.staticAnswers };
  }

  public getQuestionKey(qkey: string) {
    return this.qkey + "." + qkey;
  }

  public conditionPass(question: any, answers: any) {
    return conditionPass(question, answers);
  }
}
