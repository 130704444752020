


























































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Questions from "../components/Questions.vue";
import InformationBox from "../components/InformationBox.vue";
import levenshtein from "js-levenshtein";
import deepEqual from "deep-equal";
import { TIMEZONES } from "../timezones";
import { getMissingMandatoryAnswers } from "../conditions";

@Component({
  components: {
    Questions,
    InformationBox,
  },
})
export default class BuildingEdit extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;

  public building: any = {
    id: 0,
    name: "",
    address: "",
    areas: [],
  };
  public buildingPresence: any = null;
  public timezones: any = [];
  public hourOptions: any = [];

  public hasBuildingPreferenceChanged: boolean = false;
  public checkBuildingPreferenceChanged: boolean = false;
  public tab: string = "general";
  public buildingName: string = "";
  public buildingAddress: string = "";
  public buildingTimezone: string = "";
  public buildingReportingDemoMode: boolean = false;
  public answers: any = {};
  public answersCompleted: boolean = false;
  public initialAnswers: any = null;
  public missingAnswers: any = [];
  public questions: any = [];

  @Watch("buildingPresence", { immediate: true, deep: true })
  public checkBuildingPreferencesChanges() {
    if (this.buildingPresence === null) {
      return;
    }
    if (this.checkBuildingPreferenceChanged === false) {
      this.checkBuildingPreferenceChanged = true;
      return;
    }
    this.hasBuildingPreferenceChanged = true;
  }

  public refreshMissingAnswers() {
    this.missingAnswers = getMissingMandatoryAnswers(
      this.questions, this.answers, 'building'
    );
  }

  public async refresh() {
    this.$store
      .dispatch("loadBuilding", {
        buildingId: this.id,
        params: {
          recursive: true,
        },
      })
      .then((response: any) => {
        this.building = response;
        this.buildingName = this.building.name;
        this.buildingAddress = this.building.address;
        this.buildingTimezone = this.building.timezone;
        this.buildingReportingDemoMode = this.building.reporting_demo_mode;
        this.loading = false;
        this.updateTab();
      })
      .catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
  }

  public created() {
    // generate hour options
    const hourOptions: any = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        hourOptions.push(`${i.toString().padStart(2, "0")}:${("" + (j * 15)).padStart(2, "0")}`);
      }
    }
    this.hourOptions = hourOptions;

    this.timezones = TIMEZONES;
    if (!this.$can("manage", "building")) {
      this.tab = "presence";
    }
    if (this.$route.params.focusTab) {
      this.tab = this.$route.params.focusTab;
    }
    this.refresh();
  }

  @Watch("tab")
  public updateTab() {
    if (this.tab === "presence") {
      this.loadPresence();
    } else if (this.tab === "informations") {
      this.loadInformations();
    }
  }

  public loadPresence() {
    this.loading = true;
    this.$store
      .dispatch("loadBuildingPresence", {
        buildingId: this.id,
      })
      .then((response: any) => {
        this.checkBuildingPreferenceChanged = false;
        this.buildingPresence = response;
        this.hasBuildingPreferenceChanged = false;
        this.loading = false;
      })
      .catch((error: any) => {
        this.globalError(error);
        this.loading = false;
      });
  }

  public loadInformations() {
    this.loading = true;
    this.$store
      .dispatch("loadBuildingInformations", {
        buildingId: this.id,
      })
      .then((response: any) => {
        this.questions = response.questions;
        this.answers = response.answers;
        this.answersCompleted = response.completed;
        this.initialAnswers = JSON.parse(JSON.stringify(this.answers));
        this.refreshMissingAnswers();
        this.loading = false;
      })
      .catch((error: any) => {
        this.globalError(error);
        this.loading = false;
      });
  }

  public answersChanged() {
    return deepEqual(this.answers, this.initialAnswers) === false;
  }

  public saveInformations() {
    if (!this.answersChanged()) {
      return;
    }
    this.$store
      .dispatch("saveBuildingInformations", {
        buildingId: this.id,
        payload: {
          answers: this.answers,
        },
      })
      .then((response: any) => {
        this.answersCompleted = response.completed;
        this.refreshMissingAnswers();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      })
      .catch((error: any) => {
        this.globalError(error);
      });
  }

  public saveBuildingPreferences() {
    this.$store
      .dispatch("editBuildingPresence", {
        buildingId: this.id,
        payload: this.buildingPresence,
      })
      .then((response: any) => {
        this.buildingPresence = response;
      })
      .catch((error: any) => {
        this.globalError(error);
      });
  }

  public hoursBefore(hourLimit: any) {
    return this.hourOptions.filter((hour: any) => {
      return hour < hourLimit;
    });
  }

  public hoursAfter(hourLimit: any) {
    return this.hourOptions.filter((hour: any) => {
      return hour > hourLimit;
    });
  }

  public filterTimezones(val: string, update: any, abort: any) {
    update(() => {
      const needle = val.toLowerCase();
      this.timezones = TIMEZONES.filter((v) => v.toLowerCase().indexOf(needle) > -1);
    });
  }

  public onEditBuilding() {
    if (levenshtein(this.buildingName, this.building.name) >= 3) {
      this.$q
        .dialog({
          title: this.$t("big_change_title"),
          message: this.$t("building_change_message"),
          stackButtons: true,
          cancel: {
            label: this.$t("building_change_cancel"),
            outline: true,
          },
          ok: {
            label: this.$t("big_change_ok"),
            flat: true,
            size: "m",
            dense: true,
          },
        })
        .onCancel(() => {
          this.buildingName = this.building.name;
        })
        .onOk(() => {
          this._onEditBuilding();
        });
    } else {
      this._onEditBuilding();
    }
  }

  public _onEditBuilding() {
    const buildingId: number = this.building.id;
    const payload: any = {
      name: this.buildingName,
      address: this.buildingAddress,
      timezone: this.buildingTimezone,
    };
    if (this.$can("manage", "all")) {
      payload.reporting_demo_mode = this.buildingReportingDemoMode;
    }
    this.loading = true;
    this.$store
      .dispatch("editBuilding", {
        buildingId,
        payload,
      })
      .then(() => {
        this.refresh();
      })
      .catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
  }

  public deleteCurrentBuilding() {
    this.$q
      .dialog({
        title: this.$t("delete_title", [this.building.name]),
        message: this.$t("delete_message", [this.building.name]),
      })
      .onOk(() => {
        const buildingId: number = this.building.id;
        this.$store
          .dispatch("deleteBuilding", {
            buildingId,
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error: any) => {
            this.loading = false;
            this.globalError(error);
          });
      });
  }
}
